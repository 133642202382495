<template>
<b-overlay
  :show="loading"
  rounded="lg">
  <div>
    <b-breadcrumb style="margin-bottom:20px">
      <b-breadcrumb-item to="/">
        <feather-icon
          icon="HomeIcon"
          size="16"
          class="align-text-top" />
      </b-breadcrumb-item>
      <b-breadcrumb-item
        to="/delivery-orders/daikin"
        v-if="goBack === 'do-daikin'">List Delivery Order Daikin</b-breadcrumb-item>
      <b-breadcrumb-item
        to="/delivery-orders/sumitomo"
        v-if="goBack === 'do-sumitomo'">List Delivery Order Sumitomo</b-breadcrumb-item>
      <b-breadcrumb-item
        to="/self-collect/daikin"
        v-if="goBack === 'sc-daikin'">List Self Collect Daikin</b-breadcrumb-item>
      <b-breadcrumb-item
        to="/self-collect/sumitomo"
        v-if="goBack === 'sc-sumitomo'">List Self Collect Sumitomo</b-breadcrumb-item>
      <b-breadcrumb-item
        to="/shipping"
        v-if="goBack === 'shipping'">List Shipping</b-breadcrumb-item>
      <b-breadcrumb-item
        to="/return-delivery/daikin"
        v-if="goBack === 'return-daikin'">List Return Delivery Daikin</b-breadcrumb-item>
      <b-breadcrumb-item
        to="/return-delivery/sumitomo"
        v-if="goBack === 'return-sumitomo'">List Return Delivery Sumitomo</b-breadcrumb-item>
      <b-breadcrumb-item
        to="/retrigger-api"
        v-if="goBack === 'resend'">List Retrigger API</b-breadcrumb-item>
      <b-breadcrumb-item active>Displays Delivery Order - {{doData.status? doData.status : 'New' }}</b-breadcrumb-item>
    </b-breadcrumb>
    <b-card no-body>
      <!-- Header conditional-->
      <b-card-header v-if="pageone">
        <!-- 1. Common information this DO-->
        <h4 v-if="goBack === 'shipping'">Shipping</h4>
        <h4 v-if="goBack === 'resend'">Retrigger API</h4>
        <h4 v-if="
              doData.shippingPoint === '1001' &&
                (goBack === 'do-daikin' ||
                  goBack === 'sc-daikin' ||
                  goBack === 'return-daikin')
            ">
          Daikin Warehouse - {{doData.status? doData.status : 'New' }}
        </h4>
        <h4 v-if="
              doData.shippingPoint === '1003' &&
                (goBack === 'do-sumitomo' ||
                  goBack === 'sc-sumitomo' ||
                  goBack === 'return-sumitomo')
            ">
          Sumitomo Warehouse - {{doData.status? doData.status : 'New' }}
        </h4>
      </b-card-header>

      <b-card-header v-if="pagetwo">
        <!-- 2. List Item information this DO-->
        <h4 v-if="goBack === 'shipping'">Shipping Item List</h4>
        <h4 v-if="goBack === 'resend'">Retrigger API Item List</h4>
        <h4 v-if="
              doData.shippingPoint === '1001' &&
                (goBack === 'do-daikin' ||
                  goBack === 'sc-daikin' ||
                  goBack === 'return-daikin')
            ">
          Daikin Warehouse Item List - {{doData.status? doData.status : 'New' }}
        </h4>
        <h4 v-if="
              doData.shippingPoint === '1003' &&
                (goBack === 'do-sumitomo' ||
                  goBack === 'sc-sumitomo' ||
                  goBack === 'return-sumitomo')
            ">
          Sumitomo Warehouse Item List - {{doData.status? doData.status : 'New' }}
        </h4>
      </b-card-header>

      <b-card-header v-if="pagethree">
        <!-- 3. Detail List Item Scanned information this DO-->
        <h4 v-if="goBack === 'shipping'">Shipping Item Detail</h4>
        <h4 v-if="goBack === 'resend'">Retrigger API Item Detail</h4>
        <h4 v-if="
              doData.shippingPoint === '1001' &&
                (goBack === 'do-daikin' ||
                  goBack === 'sc-daikin' ||
                  goBack === 'return-daikin')
            ">
          Daikin Warehouse Item Detail
        </h4>
        <h4 v-if="
              doData.shippingPoint === '1003' &&
                (goBack === 'do-sumitomo' ||
                  goBack === 'sc-sumitomo' ||
                  goBack === 'return-sumitomo')
            ">
          Sumitomo Warehouse Item Detail
        </h4>
      </b-card-header>
      <!-- End of Header conditional-->

      <b-card-body>
        <!-- Modal Needed on this page-->
        <b-modal
          v-model="modalManual"
          title="Manual Entry">
          <!-- 1. Scan Manual-->
          <b-container>
            <b-row>
              <b-col
                cols="12"
                v-if="!startManual">
                <p>Instructions</p>

                <img :src="manualImage" style="width: 100%; height: auto" />
                <ul style="list-style: decimal; padding: 0 0 0 10px;">
                  <li>Click Start</li>
                  <li>Please look code below the barcode</li>
                  <li>
                    Key in 14 digit code into the input box and click on check
                    button
                  </li>
                  <li>Proceed to add the rest of codes</li>
                  <li>Click save to finish adding</li>
                </ul>

                <div
                  class="my-3"
                  style="display: flex; justify-content: center">
                  <b-button
                    variant="success"
                    @click="openManualScan">Start</b-button>
                </div>
              </b-col>

              <b-col
                cols="12"
                v-show="startManual">
                <div class="d-flex">
                  <b-form-input
                    id="manualScan"
                    ref="manualScan"
                    v-on:keyup.enter="verifySerial"
                    v-model="barcode"
                    placeholder="Input Barcode"
                    maxlength="14"
                    class="form-control">
                  </b-form-input>
                  <b-button
                    :disabled="loading"
                    variant="success"
                    class="ml-1"
                    @click="verifySerial">Add</b-button>
                </div>

                <p class="my-2">
                  Key in 14 digit code into the input box exclude 2 asterisk *
                </p>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                style="margin-top:20px; margin-bottom:20px">
                <b-table
                  :fields="itemFields"
                  :items="listItemScan"
                  show-empty
                  responsive
                  :stacked="stackedStatus">
                  <template #cell(barcode)="row">
                    {{ row.item.scannedBarcode }}
                  </template>
                  <template #cell(actions)="row">
                    <b-btn
                      variant="danger"
                      @click="remove(row.item)">
                      <feather-icon
                        icon="XIcon"
                        size="16"
                        class="align-text-top"></feather-icon>
                    </b-btn>
                  </template>
                </b-table>
              </b-col>
            </b-row>
          </b-container>

          <template #modal-footer>
            <div class="w-100 d-flex justify-content-center align-items-center"></div>
          </template>
        </b-modal>

        <b-modal
          v-model="modalScan"
          title="Scan Barcode"
          @close="clearBarcode">
          <!-- 2. Scan from Web Cam on Browser-->
          <b-container>
            <b-row>
              <b-col
                cols="12"
                v-if="!startScan">
                <p>Instructions</p>

                <img :src="barcodeImage" style="width: 100%; height: auto" />

                <ul style="list-style: decimal; padding: 0 0 0 10px;">
                  <li>Click Start</li>
                  <li>
                    Please look for barcode that has 14 digits number below it
                  </li>
                  <li>If barcode valid you will receive success message</li>
                  <li>Proceed to add the rest of codes</li>
                  <li>Click save to finish adding</li>
                </ul>

                <div
                  class="my-3"
                  style="display: flex; justify-content: center">
                  <b-button
                    variant="success"
                    @click="startScan = true">Scan</b-button>
                </div>
              </b-col>

              <b-col
                cols="12"
                v-if="startScan">
                <!-- <div class="barcode-scanner">
                                <v-quagga
                                  :onDetected="onDecode"
                                  :readerTypes="['code_39_reader']"/>
                                </div> -->

                <!-- <div class="barcode-scanner">
                                <v-quangga-image
                                  :onDetected="onDecode"
                                  :readerTypes="['code_39_reader']"/>
                                </div> -->

                <zxing-scanner @decode="(code) => (barcode = code)" />

                <b-form-input
                  v-on:keyup.enter="verifySerial"
                  v-model="barcode"
                  type="text"
                  placeholder="Item Barcode"
                  class="my-1 verifyButton"
                  maxlength="14"
                  disabled
                  style="text-color: #ffffff"></b-form-input>

                <div style="display: flex; justify-content: center">
                  <b-button
                    @click="verifySerial"
                    :disabled="barcode === null || loading"
                    variant="primary">
                    Add
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </b-container>

          <template #modal-footer>
            <div class="w-100 d-flex justify-content-center align-items-center"></div>
          </template>
        </b-modal>

        <b-modal
          v-model="modalSkip"
          title="Skip Scan">
          <!-- 3. Alert before Skip Scan item-->
          <b-container>
            <b-row>
              <b-col cols="12">
                <div class="d-flex">
                  Are you sure want to skip scan this
                  {{ getItem(selectScan.material).itemModel }} item?
                </div>
              </b-col>
            </b-row>
          </b-container>

          <template #modal-footer>
            <!-- <div class="w-100 d-flex justify-content-center align-items-center">
                                <b-button
                                  variant="success"
                                  class="ml-1"
                                  @click="verifySkip">Yes</b-button>
                            </div> -->
            <b-row class="w-100 d-flex justify-content-center align-items-center">
              <b-button
                variant="success"
                class="ml-1"
                @click="verifySkip">Yes</b-button>
              <b-button
                class="ml-1"
                variant="danger"
                @click="closeModal">No</b-button>
            </b-row>
          </template>
        </b-modal>
        <!-- End of Modal Needed on this page-->

        <!-- Modal Edit/Cancel Handler -->

        <b-modal
          v-model="modalHandler"
          v-b-modal.modal-lg
          :no-close-on-backdrop="true">
          <template #modal-header="{ close }">

            <h5>Edit/ Cancel Handler</h5>
          </template>
          <b-container>
            <b-row>
              <b-col cols="12">
                <div class="d-flex">
                  {{this.handlerMessage}}
                </div>
              </b-col>
            </b-row>
          </b-container>

          <template #modal-footer>
            <b-row class="w-100 d-flex justify-content-center align-items-center">
              <b-button
                variant="success"
                class="ml-1"
                @click="handlerOnClick">
                Go Back
              </b-button>
            </b-row>
          </template>
        </b-modal>

        <!-- End of Modal Edit/Cancel Handler -->

        <b-col cols="12">
          <b-row>
            <b-col cols="12">
              <b-button
                variant="primary"
                @click="$router.go(-1)">
                <feather-icon
                  size="16"
                  icon="HomeIcon" /> Main
                Menu</b-button>
            </b-col>
          </b-row>
        </b-col>

        <!-- Separate one by one page on this document-->
        <b-col
          cols="12"
          class="mt-2"
          v-if="pageone">
          <!-- 1. Common information this DO-->
          <b-row>
            <b-col cols="12">
              <div class="d-flex flex-column">
                <label>SAP DO Number</label>
                <p class="font-weight-bold">{{ doData.sapDo }}</p>
              </div>
            </b-col>

            <b-col cols="12">
              <div class="d-flex flex-column">
                <label>SAP SO Number</label>
                <p class="font-weight-bold">{{ doData.sapSo }}</p>
              </div>
            </b-col>

            <b-col cols="12">
              <div class="d-flex flex-column">
                <label>Delivery Date</label>
                <p class="font-weight-bold">
                  {{ dates(doData.deliveryDate) }}
                </p>
              </div>
            </b-col>

            <b-col cols="12">
              <div class="d-flex flex-column">
                <label>Delivery Time</label>
                <p class="font-weight-bold">
                  {{ showTime(doData.priority) }}
                </p>
              </div>
            </b-col>

            <b-col cols="12">
              <div class="d-flex flex-column">
                <label>Delivery Address</label>
                <p class="font-weight-bold">
                  {{ doData.deliveryAddress1 }}
                </p>
                <p class="font-weight-bold">
                  {{ doData.deliveryAddress2 }}
                </p>
                <p class="font-weight-bold">
                  {{ doData.deliveryAddress3 }}
                </p>
                <p class="font-weight-bold">{{ doData.deliveryCity }}</p>
                <p class="font-weight-bold">
                  {{ doData.deliveryPostalCode }}
                </p>
              </div>
            </b-col>

            <b-col cols="12">
              <div class="d-flex flex-column">
                <label>Business Partner</label>
                <p class="font-weight-bold">{{ doData.CustomerName1 +" "+doData.CustomerName2 }}</p>
              </div>
            </b-col>
            <b-col cols="12">
              <div class="d-flex flex-column">
                <label>Customer Information</label>
                <p class="font-weight-bold">{{ doData.CustomerName3 }}</p>
                <p class="font-weight-bold">{{ doData.phone }}</p>
              </div>
            </b-col>
            <b-col cols="12">
              <div class="d-flex flex-column">
                <label>Customer PO</label>
                <p class="font-weight-bold">
                  {{ doData.customerPo.includes('-') && doData.customerPo.startsWith('RSP') ? doData.customerPo.substring(12) : doData.customerPo }}
                </p>
              </div>
            </b-col>
            <b-col cols="12">
              <div class="d-flex flex-column">
                <label>RSP Order Number</label>
                <p class="font-weight-bold">
                  {{ doData.customerPo.includes('-') && doData.customerPo.startsWith('RSP') ? doData.customerPo.substring(0, 11) : '' }}
                </p>
              </div>
            </b-col>
            <b-col
              cols="12"
              class="mb-2">
              <b-button
                size="md"
                variant="primary"
                @click="toPage()"
                v-if="goBack !== 'other'">
                <feather-icon size="16" />
                Back to Previous
              </b-button>

            </b-col>
            <b-col
              v-if="doData.status==''|doData.status==undefined|doData.status==null"
              cols="12"
              md="3"
              class="mb-2">
              <b-button
                size="md"
                @click="toScanningInProgress"
                variant="success">Start Scanning</b-button>
            </b-col>
            <b-col
              v-else
              cols="12"
              md="3"
              class="mb-2">
              <b-button
                size="md"
                @click="nextPage"
                variant="primary">Next</b-button>
            </b-col>
          </b-row>
        </b-col>
        <b-col
          cols="12"
          class="mt-2"
          v-if="pagetwo">
          <!-- 2. List Item information this DO-->
          <b-row>
            <b-col cols="12">
              <div class="d-flex flex-column">
                <label>SAP DO Number</label>
                <p class="font-weight-bold">{{ doData.sapDo }}</p>
              </div>
            </b-col>
            <b-col cols="12">
              <div 
                v-if="doData.mark === 'Edit Delivery' || doData.mark === 'Cancel Delivery'"
                class="d-flex flex-column"
              >
                <label>Edit / Cancel DO Handler</label>
                <div 
                  class="alert-box mb-2"
                >
                  <p v-if="doData.mark === 'Cancel Delivery'" class="alert-text">
                    There is a Cancel DO for this DO. Please proceed to process the Cancel DO.
                  </p>
                  <p v-else-if="doData.mark === 'Edit Delivery'" class="alert-text">
                    There is an Edit DO for this DO. Please proceed to process the Edit DO.
                  </p>
                </div>
              </div>
            </b-col>
            <b-col cols="12">
              <h5>Select Item</h5>
            </b-col>
            <b-col
              cols="12"
              style="margin-top:20px; margin-bottom:20px">
              <b-table
                :fields="scanFields"
                :items="doData.DO_details"
                show-empty
                responsive
                :stacked="stackedStatus">
                <template #cell(actions)="row">
                  <b-button
                    @click="selectItem(row)"
                    variant="primary">Select</b-button>
                </template>

                <template #cell(material)="row">
                  {{ getItem(row.item.material).itemModel }}
                </template>
                <template #cell(qty)="row">
                  {{ row.item.qty }}
                </template>
                <template #cell(desc)="row">
                  {{ getItem(row.item.material).description }}
                </template>
                <template #cell(scan)="row">
                  {{ getSummary(row.item.material).scanQty }}
                </template>
              </b-table>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              md="2"
              class="adjust-on-mobile">
              <b-button
                @click="backPage"
                class="w-100 d-flex justify-content-center"
                variant="primary">Back</b-button>
            </b-col>
            <b-col
              md="3"
              class="adjust-on-mobile">
              <b-button
                :disabled="availability"
                @click="scanComplete"
                class="w-100 d-flex justify-content-center"
                variant="success">Submit</b-button>
            </b-col>
          </b-row>
        </b-col>
        <b-col
          cols="12"
          class="mt-2"
          v-if="pagethree">
          <!-- 3. Detail List Item Scanned information this DO-->
          <b-row>
            <b-col cols="12">
              <div class="d-flex flex-column">
                <label>SAP DO Number</label>
                <p class="font-weight-bold">{{ doData.sapDo }}</p>
              </div>
            </b-col>
            <b-col cols="12">
              <div class="d-flex flex-column">
                <label>Model No</label>
                <p class="font-weight-bold">
                  {{ getItem(selectScan.material).itemModel }}
                </p>
              </div>
            </b-col>
            <b-col cols="12">
              <div class="d-flex flex-column">
                <label>Description</label>
                <p class="font-weight-bold">
                  {{ getItem(selectScan.material).description }}
                </p>
              </div>
            </b-col>
            <b-col cols="12">
              <div class="d-flex flex-column">
                <label>DO QTY</label>
                <p class="font-weight-bold">{{ selectScan.qty }}</p>
              </div>
            </b-col>
            <b-col cols="12">
              <div class="d-flex flex-column center-on-mobile">
                <label>Scanned</label>
                <p class="font-weight-bold">
                  {{ getSummary(selectScan.material).scanQty }}/{{
                      selectScan.qty
                    }}
                </p>
              </div>
            </b-col>
          </b-row>
          <div
            style="height:90px"
            class="mobile-version">
            <b-row>
              <!-- <b-col
   cols="6"
   class="adjust-on-mobile">
                <b-button
                  @click="doScan(selectScan.material)"
                  :disabled="btnScan"
                  class="w-100 d-flex justify-content-center"
                  variant="primary"
                  >Scan</b-button
                >
              </b-col>
              <b-col
                cols="6"
                class="adjust-on-mobile">
                <b-button
                  @click="testScan(selectScan.material)"
                  :disabled="btnScan"
                  class="w-100 d-flex justify-content-center"
                  >Manuals Entry</b-button
                >
              </b-col> -->
              <b-col
                cols="6"
                class="mobile-version">
                <b-button
                  style="height:75px; padding : 30px"
                  @click="doScan(selectScan.material)"
                  :disabled="btnScan"
                  class="w-100 d-flex justify-content-center">Camera</b-button>
              </b-col>
              <b-col
                cols="6"
                class="mobile-version">
                <b-button
                  style="height:75px; padding : 30px"
                  @click="testScan(selectScan.material)"
                  :disabled="btnScan"
                  class="w-100 d-flex justify-content-center"
                  variant="primary">Scan</b-button>
              </b-col>
            </b-row>
          </div>
          <b-row>
            <b-col class="mobile-version">
              <b-button
                style="height:75px; padding : 30px; "
                @click="skipScan(selectScan)"
                :disabled="btnSkip"
                class="w-100 d-flex justify-content-center"
                variant="danger">Skip Scan</b-button>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="3">
              <b-button
                @click="doScan(selectScan.material)"
                :disabled="btnScan"
                class="w-100 d-flex justify-content-center desktop-version">Camera</b-button>
            </b-col>
            <b-col md="3">
              <b-button
                @click="testScan(selectScan.material)"
                :disabled="btnScan"
                class="w-100 d-flex justify-content-center desktop-version"
                variant="primary">Scan</b-button>
            </b-col>
            <b-col md="3">
              <b-button
                @click="skipScan(selectScan)"
                :disabled="btnSkip"
                class="w-100 d-flex justify-content-center desktop-version"
                variant="danger">Skip Scan</b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              style="margin-top:20px; margin-bottom:20px">
              <b-table
                :fields="itemFields"
                :items="listItemScan"
                show-empty
                responsive
                :stacked="stackedStatus">
                <template #cell(barcode)="row">
                  {{ row.item.scannedBarcode }}
                </template>
                <template #cell(actions)="row">
                  <b-btn
                    variant="danger"
                    @click="remove(row.item)">
                    <feather-icon
                      icon="XIcon"
                      size="16"
                      class="align-text-top"></feather-icon>
                  </b-btn>
                </template>
              </b-table>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              md="2"
              class="adjust-on-mobile">
              <b-button
                @click="backPageTwo"
                class="w-100 d-flex justify-content-center"
                variant="primary">Back</b-button>
            </b-col>
            <!-- <b-col
   md="3"
   class="adjust-on-mobile">
                                <b-button  :disabled="availability" @click="scanComplete" class="w-100 d-flex justify-content-center" variant="success">Confirm</b-button>
                            </b-col> -->
          </b-row>
        </b-col>
        <!-- End of Separate one by one page on this document-->
      </b-card-body>
    </b-card>
  </div>
</b-overlay>
</template>

<script>
import {
  mapActions
} from "vuex";
import axios from "@/axios";
import ZxingVue from "../../../../components/ZxingEdit.vue";
import {
  ValidationProvider,
  ValidationObserver
} from "vee-validate";
// import {
//   required,
//   email
// } from "@validations";
import {
  dateFormat,
  sleep
} from "@/utils/utils.js";
import FeatherIcon from "../../../../@core/components/feather-icon/FeatherIcon.vue";
import successScan from "../../../../assets/audio/successScan.mp3"
import errorScan from "../../../../assets/audio/errorScan.mp3"
import doneScan from "../../../../assets/audio/doneScan.mp3"


export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    // 'v-quagga': CustomQuanggaImage,
    // 'v-quagga': CustomQuanggaDemo,
    // 'v-quangga-image': CustomQuanggaImage,
    "zxing-scanner": ZxingVue,
    FeatherIcon,
  },
  props: ["id", "from"],
  data() {
    return {
      xStatus: false,
      tempRemoveData: "",
      tempDataList: "",
      stackedStatus: "md",
      //variabel for save detail DO
      doData: {},

      //variabel for save movement data item at scan function
      scanItemTables: [],
      scanFields: [{
          key: "material",
          label: "Item Model"
        },
        {
          key: "desc",
          label: "Description"
        },
        {
          key: "qty",
          label: "Qty"
        },
        {
          key: "scan",
          label: "Scan"
        },
        {
          key: "actions",
          label: "Action"
        },
      ],
      itemFields: [{
          key: "barcode",
          label: "Barcode"
        },
        {
          key: "actions",
          label: ""
        },
      ],

      //image for scan item modal
      barcodeImage: require("@/assets/images/drcc/barcode.png"),
      manualImage: require("@/assets/images/drcc/manual.png"),
      daikinLogo: require("@/assets/images/drcc/daikin-logo.png"),

      //initiate active page 1 to 3
      pageone: true,
      pagetwo: false,
      pagethree: false,

      postal: [],
      itemCatalog: [],

      selectData: "", //this one variable for save item info after scan function
      selectScan: "", //this one variable for save item info after select item to prepare scanning
      selectIndex: 0, //same as selectData and selectScan, but makes more sense

      barcode: null, //this one variable for save barcode
      backendItems: [], //this one variable for collect all after scanning barcode

      //modal needed variable
      modalScan: false,
      startScan: false,
      modalManual: false,
      startManual: true,
      modalSkip: false,
      loading: false,
      goBack: "", //this one variable to mark this page is opened from what table list?
      goBackStatus: "",
      //for editted&canceled handler
      modalHandler: false,
      handlerTitle: "This is a test",
      handlerMessage: "This Is a Test",
      isCanceled: false,
      isEdit: false,
    };
  },
  mounted() {
    this.goBack = this.$route.params.from;
    this.goBackStatus = this.$route.params.status;

    //temporary get api post code from axios
    /*
        axios.get('sales-and-purchase/drcc/postal').then((response) => {
            this.postal = response.data.data;
        }).catch((e) => {
            console.error(e)
        })
        */
  },
  created() {
    this.$store
      .dispatch("delivery/detailDelivery", {
        id: this.id,
      })
      .then((Detail) => {
        this.dataPopulate(Detail);
      })
      .catch((err) => {
        console.log({
          err
        });
      });
  },
  methods: {
    ...mapActions({
      updateStatus: "delivery/updateDO",
    }),
    async toScanningInProgress() {
      var editStatus;
      var idDO = this.id;
      try {
        editStatus = await this.updateStatus({
          idDelivery: idDO,
          deliveryData: {
            status: "Scanning in Progress"
          },
        });
        // If to capture canceled, status is not equal to Edit in Progress
        //expected to be undefined
        if (editStatus.data.status != "Scanning in Progress") {
          this.$bvToast.toast(
            "Edit to Scanning Progress failed, please refresh display", {
              title: "DO status not synced",
              variant: "danger",
              solid: true,
            }
          );
          return;
        }

        this.$bvToast.toast("Scanning In Progress", {
          title: "Success",
          variant: "success",
          solid: true,
        });
        this.$store
          .dispatch("delivery/detailDelivery", {
            id: this.id,
          })
          .then((Detail) => {
            this.dataPopulate(Detail);
            this.nextPage();
          })
          .catch((err) => {
            console.log({
              err
            });
          });
        await sleep(500);
        

      } catch (err) {
        console.error(err)
        if (e.response.status === 400) {
          this.$bvToast.toast(e.response.data.message, {
            title: "Failed",
            variant: "danger",
            solid: true,
          });
        } else if (e.response.status === 408 || e.response.status == 504) {
          this.$bvToast.toast("Connection error. Please refresh the page and try again", {
            title: "Failed",
            variant: "danger",
            solid: true,
          });
        } else {
          this.$bvToast.toast("Error updating DO, call administrator", {
            title: "Edit to Scanning Failed",
            variant: "danger",
            solid: true,
          });
        }

        return;
      }
    },
    toPage(doStatus = "NA") {
      var statusQuery = (doStatus == "NA") ? this.goBackStatus : doStatus;
      if (this.goBack === "resend") {
        this.$router.push({
          path: "/retrigger-api",
          query: {
            status: statusQuery,
            sapDo:this.doData.sapDo
          },
        });
      } else if (this.goBack === "redelivery-daikin") {
        this.$router.push({
          path: "/redelivery/daikin",
          query: {
            status: statusQuery,
            sapDo:this.doData.sapDo
          },
        });
      } else if (this.goBack === "redelivery-sumitomo") {
        this.$router.push({
          path: "/redelivery/sumitomo",
          query: {
            status: statusQuery,
            sapDo:this.doData.sapDo
          },
        });
      } else if (this.goBack === "return-daikin") {
        this.$router.push({
          path: "/return-delivery/daikin",
          query: {
            status: statusQuery,
            sapDo:this.doData.sapDo
          },
        });
      } else if (this.goBack === "return-sumitomo") {
        this.$router.push({
          path: "/return-delivery/sumitomo",
          query: {
            status: statusQuery,
            sapDo:this.doData.sapDo
          },
        });
      } else if (this.goBack === "do-daikin") {
        this.$router.push({
          path: "/delivery-orders/daikin",
          query: {
            status: statusQuery,
            sapDo:this.doData.sapDo
          },
        });
      } else if (this.goBack === "do-sumitomo") {
        this.$router.push({
          path: "/delivery-orders/sumitomo",
          query: {
            status: statusQuery,
            sapDo:this.doData.sapDo
          },
        });
      } else if (this.goBack === "sc-daikin") {
        this.$router.push({
          path: "/self-collect/daikin",
          query: {
            status: statusQuery,
            sapDo:this.doData.sapDo
          }
        });
      } else if (this.goBack === "sc-sumitomo") {
        this.$router.push({
          path: "/self-collect/sumitomo",
          query: {
            status: statusQuery,
            sapDo:this.doData.sapDo
          }
        });
      } else if (this.goBack === "shipping-daikin") {
        this.$router.push({
          path: "/shipping/daikin",
          query: {
            status: statusQuery,
            sapDo:this.doData.sapDo
          }
        });
      } else if (this.goBack === "shipping-sumitomo") {
        this.$router.push({
          path: "/shipping/sumitomo",
          query: {
            status: statusQuery,
            sapDo:this.doData.sapDo
          }
        });
      } else if (this.goBack === "manual-return-daikin") {
        this.$router.push({
          path: "/manual-return-delivery/daikin",
          query: {
            status: statusQuery,
            sapDo:this.doData.sapDo
          },
        });
      } else if (this.goBack === "manual-return-sumitomo") {
        this.$router.push({
          path: "/manual-return-delivery/sumitomo",
          query: {
            status: statusQuery,
            sapDo:this.doData.sapDo
          },
        });
      } else {
        this.$router.push(`/`);
        this.$bvToast.toast("Shipping Point Not Found", {
          title: "Warning",
          variant: "danger",
          solid: true,
        });
      }
    },
    handlerOnClick() {
      if (this.isCanceled) {
        this.toPage("Cancel Delivery")
      } else if (this.isEdit) {
        this.toPage("Edit")
      }

    },
    getItems() {
      var idList = this.doData.DO_details.map(x => {
        return x.material
      });
      //temporary get api items catalog from axios
      var list = idList.join("|")
      console.info('getItems list ', list)
      axios
        .get("sales-and-purchase/drcc/items/multi?idList=" + list)
        .then((response) => {
          this.itemCatalog = response.data.data;
          // console.log(this.itemCatalog)
        })
        .catch((e) => {
          console.error(e);
        });
    },
    async openManualScan() {
      this.startManual = true;
      await sleep(100);
      document.getElementById("manualScan").focus();
    },
    dataPopulate(Detail) {
      let tableIndex = 0;
      this.scanItemTables = Detail.DO_details.reduce((prev, next) => {
        
        if(next.qty === 0){
          return [...prev]
        }
        
        let expandedQty = [];
        var barcodeDO = next.scannedBarcode.map((x) => {
          return x.itemUsed;
        });
        var unscanBarcodeDO = next.returnBarcode.map((x) => {
          return x.item;
        });
        for (let i = 0; i < next.qty; i++) {
          var tempBarcode, tempUnScan;

          //for original scan Barcode
          if (barcodeDO[i] === undefined) {
            tempBarcode = "";
          } else {
            tempBarcode = barcodeDO[i];
          }

          //for list return barcode
          if (unscanBarcodeDO[i] === undefined) {
            tempUnScan = "";
          } else {
            tempUnScan = unscanBarcodeDO[i];
          }
          expandedQty.push({
            ...next,
            scannedBarcode: tempBarcode,
            returnBarcode: tempUnScan,
            qty: 1,
            index: tableIndex,
            skip: false,
          });
          tableIndex++;
        }

        return [...prev, ...expandedQty];
      }, []);

      this.doData = Detail;
      //console.info('doData ',this.doData)
      var materialList = this.doData.DO_details.map(x => {
        return x.material
      });

      //console.info('doData ',materialList);
      this.getItems(materialList);
    },
    closeModal() {
      return (this.modalSkip = false);
    },
    dates(date) {
      return dateFormat(date);
    },
    async remove(data) {
      const payload = {
        // idDo : this.id,
        _id: this.id,
        barcode: data.scannedBarcode,
        material: data.material,
      };

      this.tempDataList = data;
      // this.tempRemoveData = payload;
      this.xStatus = true;

      // let dataItemScanIndex = this.listItemScan.findIndex((item) => {
      //     return item.scannedBarcode == this.temp.scannedBarcode
      // })
      // if(dataItemScanIndex > -1){
      //     this.listItemScan.splice(dataItemScanIndex,1);
      // }
      // console.log('dataItemScan', this.listItemScan)

      // console.log('payload',payload)
      // console.log('this.listItemScan', this.listItemScan);
      var result;
      try{
        result = await axios
        .post("sales-and-purchase/drcc/delivery/remove", payload)
      }catch(err){
        console.error(err)
        this.$bvToast.toast("Remove barcode "+payload.barcode+" Failed, please try again", {
            title: "Failed",
              variant: "danger",
            solid: true,
          });
        return
      }
      this.$bvToast.toast("Remove barcode "+payload.barcode+" Successful", {
            title: "Success",
            variant: "success",
            solid: true,
          });

        this.$store
      .dispatch("delivery/detailDelivery", {
        id: this.id,
      })
      .then((Detail) => {
        this.dataPopulate(Detail);
        
      })
      .catch((err) => {
        this.$bvToast.toast("Display DO Update Failed, please refresh display", {
            title: "Failed",
              variant: "danger",
            solid: true,
          });
        console.log(
          err
        );
      });
        
      
    },

    showTime(priority) {
      var time;
      if (priority === "11") {
        time = "Morning: 9AM - 12PM";
      } else if (priority === "12") {
        time = "Afternoon: 1PM - 3PM";
      } else if (priority === "13") {
        time = "Afternoon: 3PM - 6PM";
      } else if (priority === "03") {
        time = "Self Collect AMK";
      } else if (priority === "05") {
        time = "Self Collect Sumitomo";
      } else {
        time = "";
      }
      return time;
    },
    selectItem(row) {
      this.selectScan = row.item;
      this.pagetwo = false;
      this.pagethree = true;
    },
    selectItemByIndex(index) {
      this.selectIndex = index;
      this.selectScan = this.doData.DO_details[index];
      this.pagetwo = false;
      this.pagethree = true;
    },
    scanComplete() {
      this.loading = true;
      this.updateStatus({
          idDelivery: this.id,
          deliveryData: {
            status: "Scanning Completed"
          },
        })
        .then(async (res) => {
          var isCancelled = false;
          this.$bvToast.toast("Success to Scan Completed", {
            title: "Success",
            variant: "success",
            solid: true,
          });
          if (res.draftID != null) {
            this.isEdit = true;
            this.handlerMessage = "This DO #" + this.doData.sapDo + " scanning has been completed." +
              " There is an Edit DO for this DO. Please proceed to process the Edit DO"
            this.$bvToast.toast(
              "An edit was detected, changing DO status to Edit", {
                title: "Warning",
                variant: "danger",
                solid: true,
                noAutoHide: true
              }
            );
            this.modalHandler = true;
          } else if (res.canceled) {
            this.isCanceled = true;
            this.handlerMessage = "This DO #" + this.doData.sapDo + " scanning has been completed." +
              " There is an Cancel DO for this DO. Please proceed to process the Cancel DO"
            this.$bvToast.toast(
              "A cancel was detected, changing DO status to Cancel", {
                title: "Warning",
                variant: "danger",
                solid: true,
                noAutoHide: true
              }
            );
            this.modalHandler = true;
          } else {
            await sleep(2000);
            this.toPage("Scanning Completed")
          }

        })
        .catch((err) => {
          console.log({
            err
          });
          if (e.response.data !== undefined) {
            this.$bvToast.toast(e.response.data.message, {
              title: "Failed",
              variant: "danger",
              solid: true,
            });
          } else {
            this.$bvToast.toast("Connection error. Please refresh the page and try again", {
              title: "Failed",
              variant: "danger",
              solid: true,
            });
          }
        })
        .finally(() => {
          this.loading = false;
          //this.$store.dispatch("delivery/getDeliveryOrder");
        });
    },
    getSummary(material) {
        //ini buat cari jumlah discan dan belum discan berdasarkan material id yg dicari
        let itemSummary = this.summary[material];
        if(itemSummary==undefined){
        console.info('item summary is undefined ',material);
        itemSummary={scanQty:0}
      }
        return itemSummary;
      },

    doScan(material) {
      //  var searchPostcode = this.postal.find(x=>{return x.itemModel==material});
      //  console.log('this.')
      //   if(searchPostcode!=undefined){

      this.selectData = material;

      let scanIndex = this.scanItemTables.findIndex((item) => {
        return item.scannedBarcode.length === 0 && item.material === material;
      });

      if (scanIndex < 0) {
        this.$bvToast.toast("No More Scan", {
          title: "Error",
          variant: "danger",
          solid: true,
        });
      } else {
        // this.modalManual = true;

        this.startScan = true;
        this.modalScan = true; // this is for scan from browser
      }

      // }else{
      //     this.$bvToast.toast('This Item does not have Post Code', {
      //     title: "Error",
      //     variant: "danger",
      //     solid: true,
      //     });
      // }
    },
    testScan(material) {
      //  var searchPostcode = this.postal.find(x=>{return x.itemModel==material});

      //   if(searchPostcode!=undefined){

      this.selectData = material;

      let scanIndex = this.scanItemTables.findIndex((item) => {
        return item.scannedBarcode.length === 0 && item.material === material;
      });

      if (scanIndex < 0) {
        this.modalManual = false;
        this.$bvToast.toast("No More Scan", {
          title: "Error",
          variant: "danger",
          solid: true,
        });
      } else {
        this.modalManual = true;
        this.openManualScan();
      }

      // }else{
      //     this.$bvToast.toast('This Item does not have Post Code', {
      //     title: "Error",
      //     variant: "danger",
      //     solid: true,
      //     });
      // }
    },

    skipScan(data) {
      //  var searchPostcode = this.postal.find(x=>{return x.itemModel==data.material});
      //   if(searchPostcode!=undefined){

      this.selectData = data;

      let scanIndex = this.scanItemTables.findIndex((item) => {
        return (
          item.scannedBarcode.length === 0 && item.material === data.material
        );
      });

      if (scanIndex < 0) {
        this.$bvToast.toast("No More Skip Scan", {
          title: "Error",
          variant: "danger",
          solid: true,
        });
      } else {
        this.modalSkip = true;
      }

      // }else{
      //     this.$bvToast.toast('This Item does not have Post Code', {
      //     title: "Error",
      //     variant: "danger",
      //     solid: true,
      //     });
      // }
    },

    nextPage() {
      this.pageone = false;
      this.pagetwo = true;
    },
    backPage() {
      this.pageone = true;
      this.pagetwo = false;
    },
    backPageTwo() {
      this.pagetwo = true;
      this.pagethree = false;
      this.$store
      .dispatch("delivery/detailDelivery", {
        id: this.id,
      })
      .then((Detail) => {
        this.dataPopulate(Detail);
      })
      .catch((err) => {
        console.log({
          err
        });
      });
    },
    clearBarcode() {
      this.barcode = null;
    },
    
    verifySkip() {
      this.loading = true
      axios
        .post("sales-and-purchase/drcc/delivery/skip-scan", {
          material: this.selectData.material,
          sapDo: this.doData.sapDo,
          sapSo: this.doData.sapSo,
          idDO: this.id,
        })
        .then((x) => {
          let scanIndex = this.scanItemTables.findIndex((item) => {
            return (
              item.scannedBarcode.length === 0 &&
              item.material === this.selectData.material
            );
          });

          if (scanIndex < 0) {
            this.$bvToast.toast("No More Skip Scan", {
              title: "Error",
              variant: "danger",
              solid: true,
            });
          } else {
            for (
              var searchIndex = 0; searchIndex < this.scanItemTables.length; searchIndex++
            ) {
              var selectMaterial = this.selectData.material;
              var loopMaterial = this.scanItemTables[searchIndex];
              if (
                selectMaterial === loopMaterial.material &&
                loopMaterial.scannedBarcode.length === 0
              ) {
                let cloneItemTable = JSON.parse(
                  JSON.stringify(this.scanItemTables)
                );
                let items = {
                  ...cloneItemTable[searchIndex],
                  scannedBarcode: "Skip Scan",
                  skip: true,
                };

                cloneItemTable.splice(searchIndex, 1, items);

                // Replace
                this.scanItemTables = cloneItemTable;
              }
            }
            this.$bvToast.toast("Success Skip Scan", {
              title: "Success",
              variant: "success",
              solid: true,
            });
          }
          //filter by qty req per item

          this.modalSkip = false;
          this.loading = false
        })
        .catch((e) => {
          this.loading = false
          console.log(e.response.data.message);
          // this.barcode = null;
          if (e.response.data !== undefined) {
            this.$bvToast.toast(e.response.data.message, {
              title: "Failed",
              variant: "danger",
              solid: true,
            });
          } else {
            this.$bvToast.toast("Connection error. Please refresh the page and try again", {
              title: "Failed",
              variant: "danger",
              solid: true,
            });
          }
        });

      //filter by qty req per item

    },
    verifySerial() {
      if(this.loading){
        this.$bvToast.toast("Scanning Barcode still in progress. Please refresh the page and try again", {
          title: "Failed",
          variant: "danger",
          solid: true,
        });
        return;
      }
      this.loading = true
      // if (this.serial !== null) {
      //  let padRight = 1;
      //  let padLeft = 1;
      let postal = "";
      let serial = "";
      // let barcode = '';
      // let model = '';

      //  if (this.inputType === 'Manual') {
      //    postal = this.serial.substr(0, 6);
      //    serial = this.serial.substr(6, this.serial.length);
      //    model = this.model;
      //  } else {
      console.log("Scan!!");
      let localSerial = this.barcode;
      localSerial = localSerial.replaceAll("*", "");
      console.log(localSerial);

      postal = localSerial.substr(0, 6);
      serial = localSerial.substr(6, 8);
      // model = this.model;
      //  }
      let validateBarcodeExist = this.scanItemTables.find((item) => {
        return item.scannedBarcode === localSerial;
      });
      console.log("localSerial", localSerial);
      console.log("validateBarcodeExist", validateBarcodeExist);
      if (
        validateBarcodeExist == undefined ||
        validateBarcodeExist.length < 1
      ) {
        axios
          .post("sales-and-purchase/drcc/delivery/verify", {
            postal: postal,
            barcode: localSerial,
            serial: serial,
            material: this.selectData,
            sapDo: this.doData.sapDo,
            sapSo: this.doData.sapSo,
            idDO: this.id,
          })
          .then((response) => {
            let item = response.data.data;

            this.backendItems.push({
              itemModel: item.itemModel,
              status: "Valid",
              barcode: item.barcode,
              apiData: item,
            });

            //filter by qty req per item
            let scanIndex = this.scanItemTables.findIndex((item) => {
              return (
                item.scannedBarcode.length === 0 &&
                item.material === this.selectData
              );
            });

            if (scanIndex < 0) {
              this.$bvToast.toast("No More Scan", {
                title: "Error",
                variant: "danger",
                solid: true,
              });
            } else {
              let cloneItemTable = JSON.parse(
                JSON.stringify(this.scanItemTables)
              );
              let item = {
                ...cloneItemTable[scanIndex],
                scannedBarcode: this.barcode,
              };
              console.log("cloneItemTable", cloneItemTable);

              this.scannedBarcode = null;

              cloneItemTable.splice(scanIndex, 1, item);

              // Replace
              this.scanItemTables = cloneItemTable;

              //change skip status
              this.selectScan.skip === true;
            }
            //filter by qty req per item

            this.$bvToast.toast("Success Verify Barcode", {
              title: "Success",
              variant: "success",
              solid: true,
            });

            if (this.selectScan.qty == this.getSummary(this.selectScan.material).scanQty) {
              const audioElement = new Audio(doneScan);
              audioElement.play();

              this.modalManual = false;
            } else {
              const audioElement = new Audio(successScan);
              audioElement.play();
            }

            this.barcode = null;
            this.loading = false
          })
          .catch((e) => {
            this.loading = false
            const audioElement = new Audio(errorScan);
            audioElement.play();

            this.$bvToast.toast(e.response.data.message, {
              title: "Failed",
              variant: "danger",
              solid: true,
            });
          });
      } else {
        const audioElement = new Audio(errorScan);
        audioElement.play();
        this.loading = false
        this.$bvToast.toast("Barcode Already Exists", {
          title: "failed",
          variant: "danger",
          solid: true,
        });
      }

      this.modalScan = false; // this is for scan from browser
      return this.listItemScan;
      // }
    },
    getItem(data) {
      var searchItem = this.itemCatalog.find((x) => {
        return x._id == data;
      });
      var result;
      if (searchItem === undefined) {
        result = {
          itemModel: " ",
        };
      } else {
        result = searchItem;
      }
      return result;
    },
  },
  computed: {
    // removeSkipScan(){
    //     let dataItemScanIndex = this.listItemScan.findIndex((item) => {
    //         return item.scannedBarcode == this.temp.scannedBarcode
    //     })
    //     if(dataItemScanIndex > -1){
    //         this.listItemScan.splice(dataItemScanIndex,1);
    //     }
    //     console.log('dataItemScan', this.listItemScan)
    //     return this.listItemScan
    // },
    summary() {
      return this.scanItemTables.reduce((prev, next) => {
        if (prev[next.material] === undefined) {
          prev[next.material] = {
            scanQty: 0,
            emptyQty: 0
          };
        }

        if (next.scannedBarcode === "") {
          prev[next.material].emptyQty += 1;
        } else {
          prev[next.material].scanQty += 1;
        }
        return prev;
      }, {});
    },
    availability() {
      console.log("this.scanItemTables", this.scanItemTables);
      var searchBarcode = this.scanItemTables.map((element) => {
        return element.scannedBarcode;
      });
      if (searchBarcode==undefined){
        //disable scan complete button if searchBarcode is undefined
        return true
      }

      
      // if(searchBarcode.length<=0){
      //   //disable scan complete button if searchBarcode length is 0
      //   return true;
      // }
      
      var nextStep = false;
      if (searchBarcode.includes("")) {
        nextStep = true;
      } else {
        nextStep = false;
      }

      return nextStep;
    },
    listItemScan() {
      var result = this.scanItemTables.filter((x) => {
        return x.material === this.selectScan.material;
      });
      /**
       
      if (this.xStatus) {
        let dataItemScanIndex = result.findIndex((item) => {
          return item.scannedBarcode == this.tempDataList.scannedBarcode;
        });
        if (dataItemScanIndex > -1) {
          result.splice(dataItemScanIndex, 1);
          let scanIndex = this.scanItemTables.findIndex((item) => {
            return (
              item.scannedBarcode.length !== 0 &&
              item.scannedBarcode === this.tempDataList.scannedBarcode
            );
          });
          for (
            var searchIndex = 0; searchIndex < this.scanItemTables.length; searchIndex++
          ) {
            let cloneItemTable = JSON.parse(
              JSON.stringify(this.scanItemTables)
            );
            let items = {
              ...cloneItemTable[scanIndex],
              scannedBarcode: "",
              skip: false,
            };
            cloneItemTable.splice(scanIndex, 1, items);
            // Replace
            this.scanItemTables = cloneItemTable;
          }
          this.xStatus = false;
        }
        // axios.post('sales-and-purchase/drcc/delivery/remove', this.tempRemoveData).then((response) => {
        //     console.log('axios response', response)
        //     return result
        // }).catch((err) => {
        //     console.log({err})
        // })
      }
      * 
       */
      var cob = result.filter((x) => {
        return x.scannedBarcode != "";
      });
      //this.xStatus = false;
      return cob;
    },
    btnScan() {
      var getSkip = this.listItemScan.map((get) => {
        return get.skip;
      });

      var countScan = this.getSummary(this.selectScan.material).scanQty;
      if (countScan === 0) {
        return false;
      } else if (countScan > 0 && getSkip.includes(false)) {
        return false;
      } else {
        return true;
      }
    },
    btnSkip() {
      var getSkip = this.listItemScan.map((get) => {
        return get.skip;
      });

      var countScan = this.getSummary(this.selectScan.material).scanQty;

      if (countScan === 0) {
        return false;
      } else if (countScan > 0 && getSkip.includes(true)) {
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>

<style scoped>
.hide-at-all {
  display: none !important;
}

.alert-box {
  border: 2px solid black; /* Border kotak hitam */
  padding: 10px;
  border-radius: 5px; /* Jika ingin rounded corner */
}

.alert-text {
  color: red; /* Teks alert berwarna merah */
  font-weight: bold;
}

/* For mobile phones: */
@media (max-width: 761px) {
  .adjust-on-mobile {
    margin-bottom: 10px !important;
  }

  .center-on-mobile {
    align-items: center !important;
  }

  .paging-center-mobile {
    justify-content: center !important;
  }
}

@media (min-width: 761px) {
  .mobile-version {
    display: none !important;
  }
}

@media (max-width: 760px) {
  .desktop-version {
    display: none !important;
  }
}
</style>
